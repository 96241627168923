import { HttpRequestMethodTypes } from 'types';
import { fetchWithRetry } from 'utils/fetchWithRetry';
import { ThemeName as TenantName } from 'themes';
import { isDevelopment } from 'utils/environment';
import { DisclaimerResponse, isDisclaimerResponse, isErrorResponse } from './providers.types';

import type Listing from 'data/listing';

const STAGING_URL = 'https://stage.zoocasa.com';
const PATHS = {
  LOCAL: 'v1/providers/disclaimers',
  PRODUCTION: 'services/providers-compliance/v1/providers/disclaimers',
} as const;

const getProviderDisclaimerBaseUrl = (host: string): string => {
  const isUsingStageUrl = host === STAGING_URL;
  const path = (isDevelopment && !isUsingStageUrl) ? PATHS.LOCAL : PATHS.PRODUCTION;
  return `${host}/${path}`;
};

/**
 * Fetches provider disclaimer information from the compliance service
 *
 * @param host The host to use for the request
 * @param providerId The unique identifier for the MLS provider
 * @param tenantName The tenant requesting the disclaimer information
 * @param listingInfo Optional listing information
 *
 * @returns DisclaimerResponse on successful fetch with valid disclaimer,
 *  undefined on any error or invalid response
 */
export async function getProviderDisclaimer(
  host: string,
  providerId: string,
  tenantName: TenantName,
  listingInfo?: Listing
): Promise<DisclaimerResponse | undefined> {
  try {
    const baseURL = getProviderDisclaimerBaseUrl(host);
    const responseBlob = await fetchWithRetry(`${baseURL}/${providerId}/${tenantName}`, {
      method: HttpRequestMethodTypes.POST,
      body: JSON.stringify(listingInfo),
    });

    const response = await responseBlob.json();

    if (!responseBlob.ok) {
      if (isErrorResponse(response)) {
        console.error('Provider disclaimer service error:', response.error, response.status);
        return undefined;
      }
      console.error('Provider disclaimer service returned error:', responseBlob.status);
      return undefined;
    }

    if (isDisclaimerResponse(response)) {
      return response;
    }

    console.error('Invalid response format from provider disclaimer service:', response);
    return undefined;
  } catch (error: any) {
    console.error('Failed to fetch provider disclaimer:', error);
    return undefined;
  }
}