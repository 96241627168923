/**
 * Represents a disclaimer for a specific provider
 */
export type Disclaimer = {
    /** The main disclaimer text, often contains HTML */
    Disclaimer: string;
    /** Attribution text showing who provided the listing */
    CourtesyOf: string;
    /** Information about the managing broker */
    ManagingBroker: string;
  };

/**
 * Response structure from the compliance service disclaimer endpoint
 */
export type DisclaimerResponse = {
    /** The disclaimer object containing all relevant disclaimer information */
    disclaimer: Disclaimer;
  };

/**
 * Error response from the compliance service
 */
export type ErrorResponse = {
    /** Error message from the service */
    error: string;
    /** HTTP status code */
    status: number;
  };

/**
 * Type guards for response validation
 */
export function isDisclaimerResponse(response: unknown): response is DisclaimerResponse {
  return (
    typeof response === 'object' &&
      response !== null &&
      'disclaimer' in response &&
      typeof (response as DisclaimerResponse).disclaimer === 'object'
  );
}

/**
 * Type guard for error response validation
 */
export function isErrorResponse(response: unknown): response is ErrorResponse {
  return (
    typeof response === 'object' &&
      response !== null &&
      'error' in response &&
      'status' in response
  );
}