import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { ProvinceOrStateCode, isUsStateCode } from 'utils/province_or_state';
import formatDate from 'utils/format-date';
import { buildClassName } from 'utils/build-class-name';
import BrokerageLogos from 'components/brokerage-logos';
import styles from './style.module.scss';
import { ThemeName as TenantName } from 'themes';
import { createProvidersComplianceApi, Disclaimer } from 'data/providers-compliance';
import { courtesyProviderIds } from 'constants/mlsProviderIds';

import type Listing from 'data/listing';

type ProviderDisclaimerProps = {
  providerId: number;
  tenantName: TenantName;
  listing?: Listing;
  renderCourtesyMessage?: (disclaimer: Disclaimer) => React.ReactNode;
  renderFooterMessage?: (disclaimer: Disclaimer) => React.ReactNode;
}

export default function ProviderDisclaimer({
  providerId,
  tenantName,
  listing,
}: ProviderDisclaimerProps) {
  const isListing = !!listing;
  const isAmericanListing = isListing && isUsStateCode(listing.province?.toLowerCase() as ProvinceOrStateCode);

  const { data: disclaimer } = useQuery({
    queryKey: ['disclaimer', providerId, tenantName, listing],
    queryFn: () => {
      const providerCompliance = createProvidersComplianceApi();
      return providerCompliance.getDisclaimer(providerId.toString(), tenantName, listing);
    },
    staleTime: 5 * 60 * 1000, // 5 minutes
  });

  if (!disclaimer || !disclaimer.Disclaimer)
    return null;

  return (
    <div className={buildClassName(styles.component, !isListing && styles['area-disclaimer'])}>
      {!courtesyProviderIds.includes(providerId) && isListing && isAmericanListing && !!disclaimer.CourtesyOf.length && <p className={styles.courtesy}>{disclaimer.CourtesyOf}</p>}
      {isListing && !isAmericanListing && listing.brokerage && <p className={styles.courtesy}>Listed by: {listing.brokerage}</p>}
      {isListing && listing.isVancouverFeed && <p className={styles.courtesy}>{listing.brokerageEmail} {listing.brokeragePhone}</p>}
      <BrokerageLogos providerId={providerId} listing={listing} />
      <div className={styles.disclaimer}>
        <div dangerouslySetInnerHTML={{ __html: disclaimer.Disclaimer }} />
      </div>

      {disclaimer.ManagingBroker && <p className={styles.courtesy}>{disclaimer.ManagingBroker}</p>}

      {isAmericanListing && listing.lastFetched &&
        <p>Data last updated at: {formatDate(listing.lastFetched)}</p>
      }
    </div>
  );
}
