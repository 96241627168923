import { ThemeName as TenantName } from 'themes';
import { Disclaimer } from './providers.types';
import { getProviderComplianceHost } from 'utils/host-config';
import { getProviderDisclaimer } from './providers.fetcher';
import type Listing from 'data/listing';

export type ProvidersComplianceApiType = {
/**
 * Fetches the disclaimer information associated with a given provider.
 *
 * @param host The host to use for the request
 * @param providerId The unique identifier for the MLS provider
 * @param tenantName The tenant requesting the disclaimer information
 * @param listingInfo Optional listing information
 *
 * @returns DisclaimerResponse on successful fetch with valid disclaimer,
 *  undefined on any error or invalid response
 */
  getDisclaimer: (providerId: string, tenantName: TenantName, listingInfo?: Listing) => Promise<Disclaimer | undefined>;
}

class ProvidersComplianceApi implements ProvidersComplianceApiType {
  private constructor(private readonly host: string) {}

  static create(host: string = getProviderComplianceHost()): ProvidersComplianceApi {
    return new ProvidersComplianceApi(host);
  }

  async getDisclaimer(providerId: string, tenantName: TenantName, listingInfo?: Listing): Promise<Disclaimer | undefined> {
    const disclaimerResponse = await getProviderDisclaimer(this.host, providerId, tenantName, listingInfo);
    return disclaimerResponse?.disclaimer;
  }
}

/**
 * Creates a new ProviderComplianceApiType instance with the given host.
 *
 * @param host The host to use for the request. Defaults to the result of `getProviderComplianceHost()`
 * @returns A new ProvidersComplianceApiType instance
 */
export const createProvidersComplianceApi = ProvidersComplianceApi.create;